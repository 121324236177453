<template>
  <b-container fluid>
    <bo-page-title></bo-page-title>
    <section id="home">
      <b-tabs>
        <b-tab title="Page Settings">
          <validation-observer ref="VForm" v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(doSubmit)">
              <b-card no-body>
                <b-card-header>
                  <b-row>
                    <b-col lg=8>
                      <h5 class="card-title">Information Section</h5>
                    </b-col>
                  </b-row>
                </b-card-header>
                <b-card-body>
                  <b-row>
                    <b-col md=4>
                      <b-form-group label-for="titleID">
                        <label>Title (ID) <span class="text-danger mr5">*</span></label>
                        <b-form-input v-model="row.contentInformation.title_id" placeholder="e.g. Alumunium Casting"></b-form-input>
                        <VValidate name="Title (ID)" v-model="row.contentInformation.title_id" rules="required|min:3|max:160" />
                      </b-form-group>
                    </b-col>
                    <b-col md=4>
                      <b-form-group label-for="titleEN">
                        <label>Title (EN) <span class="text-danger mr5">*</span></label>
                        <b-form-input v-model="row.contentInformation.title_en" placeholder="e.g. Alumunium Casting"></b-form-input>
                        <VValidate name="Title (EN)" v-model="row.contentInformation.title_en" rules="required|min:3|max:160" />
                      </b-form-group>
                    </b-col>
                    <b-col md=4>
                      <b-form-group label-for="titleJP">
                        <label>Title (JP) <span class="text-danger mr5">*</span></label>
                        <b-form-input v-model="row.contentInformation.title_jp" placeholder="e.g. 同窓会キャスティング"></b-form-input>
                        <VValidate name="Title (JP)" v-model="row.contentInformation.title_jp" rules="required|min:3|max:160" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md=4>
                      <b-form-group label-for="descID">
                        <label>Description (ID)</label>
                        <b-form-textarea :rows="3" v-model="row.contentInformation.desc_id"></b-form-textarea>
                        <VValidate name="Description (ID)" v-model="row.contentInformation.desc_id" rules="min:3" />
                      </b-form-group>
                    </b-col>
                    <b-col md=4>
                      <b-form-group label-for="descID">
                        <label>Description (EN)</label>
                        <b-form-textarea :rows="3" v-model="row.contentInformation.desc_en"></b-form-textarea>
                        <VValidate name="Description (EN)" v-model="row.contentInformation.desc_en" rules="min:3" />
                      </b-form-group>
                    </b-col>
                    <b-col md=4>
                      <b-form-group label-for="descID">
                        <label>Description (JP)</label>
                        <b-form-textarea :rows="3" v-model="row.contentInformation.desc_jp"></b-form-textarea>
                        <VValidate name="Description (JP)" v-model="row.contentInformation.desc_jp" rules="min:3" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>

              <b-card no-body>
                <b-card-header>
                  <b-row>
                    <b-col lg=8>
                      <h5 class="card-title">Image Section</h5>
                    </b-col>
                  </b-row>
                </b-card-header>
                <b-card-body>
                  <b-row>
                    <b-col md=12 class="p-4 border">
                      <b-row>
                        <b-col lg="3" md="4" class="slider-item" v-for="(image, index) in row.contentImage" :key="index">
                          <div class="card-img">
                            <b-img fluid class="card-img__media" :src="uploader(image.image)" :blank="!image.image" blank-color="#ccc"/>
                            <div class="bullet-cta">
                              <b-button
                                variant="secondary"
                                size="sm"
                                pill
                                class="btn-icon"
                                triggers="hover"
                                v-b-tooltip.hover="'Update'"
                                @click="editImage(image)"
                              >
                                <i class="fas fa-pencil-alt" />
                              </b-button>
                              <b-button
                                variant="danger"
                                size="sm"
                                pill
                                class="btn-icon"
                                v-b-tooltip.hover="'Delete'"
                                @click="deleteImage(index)"
                              >
                                <i class="far fa-trash-alt" />
                              </b-button>
                            </div>
                          </div>
                        </b-col>
                        <b-col lg="3" md="4">
                          <a href="javascript:;" class="card-img-adder" @click="addImage">
                            <i class="fas fa-plus"></i>
                            <span>Add Image</span>
                          </a>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>

              <b-card no-body>
                <b-card-header>
                  <b-row>
                    <b-col lg=8>
                      <h5 class="card-title">Machine &amp; Equipment</h5>
                    </b-col>
                  </b-row>
                </b-card-header>
                <b-card-body>
                  <div class="d-flex align-items-center">              
                    <b-form-checkbox id="showMachine" value="Y" unchecked-value="N" name="showMachine" v-model="row.showMachine">Show Section?</b-form-checkbox>
                  </div>
                  <hr />
                  <template v-if="row.showMachine == 'Y'">
                    <b-row>
                      <b-col md=4>
                        <b-form-group label-for="titleMachineID">
                          <label>Title (ID) <span class="text-danger mr5">*</span></label>
                          <b-form-input v-model="row.contentMachine.title_id"></b-form-input>
                          <VValidate vid="machine_title_id" name="Title (ID)" v-model="row.contentMachine.title_id" rules="required|min:3|max:160" />
                        </b-form-group>
                      </b-col>
                      <b-col md=4>
                        <b-form-group label-for="titleMachineEN">
                          <label>Title (EN) <span class="text-danger mr5">*</span></label>
                          <b-form-input v-model="row.contentMachine.title_en"></b-form-input>
                          <VValidate vid="machine_title_en" name="Title (EN)" v-model="row.contentMachine.title_en" rules="required|min:3|max:160" />
                        </b-form-group>
                      </b-col>
                      <b-col md=4>
                        <b-form-group label-for="titleMachineEN">
                          <label>Title (JP) <span class="text-danger mr5">*</span></label>
                          <b-form-input v-model="row.contentMachine.title_jp"></b-form-input>
                          <VValidate vid="machine_title_jp" name="Title (JP)" v-model="row.contentMachine.title_jp" rules="required|min:3|max:160" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row class="mt-2">
                      <b-col md=4>
                        <b-form-group label-for="descMachineID">
                          <label>Description (ID)</label>
                          <b-form-textarea v-model="row.contentMachine.desc_id"></b-form-textarea>
                          <VValidate vid="machine_desc_id" name="Description (ID)" v-model="row.contentMachine.desc_id" rules="min:3" />
                        </b-form-group>
                      </b-col>
                      <b-col md=4>
                        <b-form-group label-for="descMachineEN">
                          <label>Description (EN)</label>
                          <b-form-textarea v-model="row.contentMachine.desc_en"></b-form-textarea>
                          <VValidate vid="machine_desc_en" name="Description (EN)" v-model="row.contentMachine.desc_en" rules="min:3" />
                        </b-form-group>
                      </b-col>
                      <b-col md=4>
                        <b-form-group label-for="descMachineJP">
                          <label>Description (JP)</label>
                          <b-form-textarea v-model="row.contentMachine.desc_jp"></b-form-textarea>
                          <VValidate vid="machine_desc_jp" name="Description (JP)" v-model="row.contentMachine.desc_jp" rules="min:3" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-card class="border">
                      <b-row class="mt-2 gutter-3">
                        <b-col md=4 v-for="(value, index) in row.contentMachine.lists" :key="index">
                          <b-row class="align-items-center border p-2">
                            <b-col md=9>
                              <p class="m-0">{{ value.title_id }} <strong>({{ value.unit }} Unit)</strong></p>
                            </b-col>
                            <b-col md=3>
                              <div class="d-flex justify-content-end text-right">
                                <b-button
                                  variant="outline-warning"
                                  pill
                                  @click="editMachine(value)"
                                  class="btn-icon mr-2"
                                  v-b-tooltip.hover="'Update'"
                                >
                                  <i class="fas fa-pencil-alt" />
                                </b-button>
                                <b-button
                                  variant="outline-danger"
                                  pill
                                  class="btn-icon"
                                  @click="deleteMachine(index)"
                                  v-b-tooltip.hover="'Delete'"
                                >
                                  <i class="fas fa-trash" />
                                </b-button>
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col>
                          <b-button type="button" variant="outline-success" @click="addMachine">Add Machine</b-button>
                        </b-col>
                      </b-row>
                    </b-card>
                  </template>
                </b-card-body>
              </b-card>

              <b-card no-body>
                <b-card-header>
                  <b-row>
                    <b-col lg=8>
                      <h5 class="card-title">Our Client</h5>
                    </b-col>
                  </b-row>
                </b-card-header>
                <b-card-body>
                  <div class="d-flex align-items-center">              
                    <b-form-checkbox id="showClient" value="Y" unchecked-value="N" name="showClient" v-model="row.showClient">Show Section?</b-form-checkbox>
                  </div>
                  <hr />
                  <template v-if="row.showClient == 'Y'">
                    <b-row>
                      <b-col md=4>
                        <b-form-group label-for="titleClientID">
                          <label>Title (ID) <span class="text-danger mr5">*</span></label>
                          <b-form-input v-model="row.contentClient.title_id"></b-form-input>
                          <VValidate vid="client_title_id" name="Title (ID)" v-model="row.contentClient.title_id" rules="required|min:3|max:160" />
                        </b-form-group>
                      </b-col>
                      <b-col md=4>
                        <b-form-group label-for="titleClientEN">
                          <label>Title (EN) <span class="text-danger mr5">*</span></label>
                          <b-form-input v-model="row.contentClient.title_en"></b-form-input>
                          <VValidate vid="client_title_en" name="Title (EN)" v-model="row.contentClient.title_en" rules="required|min:3|max:160" />
                        </b-form-group>
                      </b-col>
                      <b-col md=4>
                        <b-form-group label-for="titleClientEN">
                          <label>Title (JP) <span class="text-danger mr5">*</span></label>
                          <b-form-input v-model="row.contentClient.title_jp"></b-form-input>
                          <VValidate vid="client_title_jp" name="Title (JP)" v-model="row.contentClient.title_jp" rules="min:3" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row class="mt-2">
                      <b-col md=4>
                        <b-form-group label-for="descMachineID">
                          <label>Description (ID)</label>
                          <b-form-textarea v-model="row.contentClient.desc_id"></b-form-textarea>
                          <VValidate vid="client_desc_id" name="Description (ID)" v-model="row.contentClient.desc_id" rules="min:3" />
                        </b-form-group>
                      </b-col>
                      <b-col md=4>
                        <b-form-group label-for="descMachineEN">
                          <label>Description (EN)</label>
                          <b-form-textarea v-model="row.contentClient.desc_en"></b-form-textarea>
                          <VValidate vid="client_desc_en" name="Description (EN)" v-model="row.contentClient.desc_en" rules="min:3" />
                        </b-form-group>
                      </b-col>
                      <b-col md=4>
                        <b-form-group label-for="descMachineJP">
                          <label>Description (JP)</label>
                          <b-form-textarea v-model="row.contentClient.desc_jp"></b-form-textarea>
                          <VValidate vid="client_desc_jp" name="Description (JP)" v-model="row.contentClient.desc_jp" rules="min:3" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-card class="border p-4">
                      <b-row>
                        <b-col lg="3" md="4" class="slider-item" v-for="(value, index) in row.contentClient.lists" :key="index">
                          <div class="card-img">
                            <b-img fluid class="card-img__media" :src="uploader(value.image)" :blank="!value.image" blank-color="#ccc"/>
                            <div class="bullet-cta">
                              <b-button
                                variant="secondary"
                                size="sm"
                                pill
                                class="btn-icon"
                                triggers="hover"
                                v-b-tooltip.hover="'Update'"
                                @click="editClient(value)"
                              >
                                <i class="fas fa-pencil-alt" />
                              </b-button>
                              <b-button
                                variant="danger"
                                size="sm"
                                pill
                                class="btn-icon"
                                v-b-tooltip.hover="'Delete'"
                                @click="deleteClient(index)"
                              >
                                <i class="far fa-trash-alt" />
                              </b-button>
                            </div>
                          </div>
                        </b-col>
                        <b-col lg="3" md="4">
                          <a href="javascript:;" class="card-img-adder" @click="addClient">
                            <i class="fas fa-plus"></i>
                            <span>Add Client</span>
                          </a>
                        </b-col>
                      </b-row>
                    </b-card>
                  </template>
                </b-card-body>
              </b-card>

              <b-card no-body>
                <b-card-header>
                  <b-row>
                    <b-col lg=8>
                      <h5 class="card-title">Product</h5>
                    </b-col>
                  </b-row>
                </b-card-header>
                <b-card-body>
                  <div class="d-flex align-items-center">              
                    <b-form-checkbox id="showProduct" value="Y" unchecked-value="N" name="showProduct" v-model="row.showProduct">Show Section?</b-form-checkbox>
                  </div>
                  <hr />
                  <template v-if="row.showProduct == 'Y'">
                    <b-row>
                      <b-col md=4>
                        <b-form-group label-for="titleProductID">
                          <label>Title (ID) <span class="text-danger mr5">*</span></label>
                          <b-form-input v-model="row.contentProduct.title_id"></b-form-input>
                          <VValidate vid="product_title_id" name="Title (ID)" v-model="row.contentProduct.title_id" rules="required|min:3|max:160" />
                        </b-form-group>
                      </b-col>
                      <b-col md=4>
                        <b-form-group label-for="titleProductEN">
                          <label>Title (EN) <span class="text-danger mr5">*</span></label>
                          <b-form-input v-model="row.contentProduct.title_en"></b-form-input>
                          <VValidate vid="product_title_en" name="Title (EN)" v-model="row.contentProduct.title_en" rules="required|min:3|max:160" />
                        </b-form-group>
                      </b-col>
                      <b-col md=4>
                        <b-form-group label-for="titleProductEN">
                          <label>Title (JP) <span class="text-danger mr5">*</span></label>
                          <b-form-input v-model="row.contentProduct.title_jp"></b-form-input>
                          <VValidate vid="product_title_jp" name="Title (JP)" v-model="row.contentProduct.title_jp" rules="min:3" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row class="mt-2">
                      <b-col md=4>
                        <b-form-group label-for="descProductID">
                          <label>Description (ID)</label>
                          <b-form-textarea v-model="row.contentProduct.desc_id"></b-form-textarea>
                          <VValidate vid="product_desc_id" name="Description (ID)" v-model="row.contentProduct.desc_id" rules="min:3" />
                        </b-form-group>
                      </b-col>
                      <b-col md=4>
                        <b-form-group label-for="descProductEN">
                          <label>Description (EN)</label>
                          <b-form-textarea v-model="row.contentProduct.desc_en"></b-form-textarea>
                          <VValidate vid="product_desc_en" name="Description (EN)" v-model="row.contentProduct.desc_en" rules="min:3" />
                        </b-form-group>
                      </b-col>
                      <b-col md=4>
                        <b-form-group label-for="descProductJP">
                          <label>Description (JP)</label>
                          <b-form-textarea v-model="row.contentProduct.desc_jp"></b-form-textarea>
                          <VValidate vid="product_desc_jp" name="Description (JP)" v-model="row.contentProduct.desc_jp" rules="min:3" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-card class="border p-4">
                      <b-row>
                        <b-col lg="3" md="4" class="slider-item" v-for="(value, index) in row.contentProduct.lists" :key="index">
                          <div class="card-img">
                            <b-img fluid class="card-img__media" :src="uploader(value.image)" :blank="!value.image" blank-color="#ccc"/>
                            <span class="card-img__title">{{ value.title_id }}</span>
                            <div class="bullet-cta">
                              <b-button
                                variant="secondary"
                                size="sm"
                                pill
                                class="btn-icon"
                                triggers="hover"
                                v-b-tooltip.hover="'Update'"
                                @click="editProduct(value)"
                              >
                                <i class="fas fa-pencil-alt" />
                              </b-button>
                              <b-button
                                variant="danger"
                                size="sm"
                                pill
                                class="btn-icon"
                                v-b-tooltip.hover="'Delete'"
                                @click="deleteProduct(index)"
                              >
                                <i class="far fa-trash-alt" />
                              </b-button>
                            </div>
                          </div>
                        </b-col>
                        <b-col lg="3" md="4" class="mt-2">
                          <a href="javascript:;" class="card-img-adder" @click="addProduct">
                            <i class="fas fa-plus"></i>
                            <span>Add Product</span>
                          </a>
                        </b-col>
                      </b-row>
                    </b-card>
                  </template>
                </b-card-body>
              </b-card>

              <b-card no-body>
                <b-card-footer>
                  <b-row>
                    <b-col lg="12" class="text-right">
                      <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Cancel</button>
                      <b-button type="submit" variant="primary" class="btn-rounded" @click="doSubmit">Save Changes</b-button>
                    </b-col>
                  </b-row>
                </b-card-footer>
              </b-card>
              
            </b-form>
          </validation-observer>
          
          <b-modal id="modalImage" title="Form Image" no-close-on-backdrop>
            <validation-observer
              v-slot="{ handleSubmit }" ref="VFormImage"
            >
              <b-form @submit.prevent="handleSubmit(submitImage)">
                <b-card-body>
                  <b-row>
                    <b-col md=12>
                      <div class="form-group">
                        <label class="control-label">Image <span class="text-danger">*</span></label>         
                        <Uploader v-model="dataModal.image" type="lini_produk"/>
                        <VValidate 
                          name="Image" 
                          v-model="dataModal.image"
                          rules="required" 
                        />
                      </div>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-form>
            </validation-observer>
            <template #modal-footer="{close}">
              <div class="text-right">
                <b-button class="btn-rounded" variant="outline-secondary" @click="close">Cancel</b-button>
                <b-button type="button" @click="submitImage" class="btn-rounded ml-2" variant="primary">Save Changes</b-button>
              </div>
            </template>
          </b-modal>
          <b-modal id="modalClient" title="Form Client" no-close-on-backdrop>
            <validation-observer
              v-slot="{ handleSubmit }" ref="VFormClient"
            >
              <b-form @submit.prevent="handleSubmit(submitClient)">
                <b-card-body>
                  <b-row>
                    <b-col md=12>
                      <div class="form-group">
                        <label class="control-label">Image <span class="text-danger">*</span></label>         
                        <Uploader v-model="dataModal.image" type="client"/>
                        <VValidate 
                          name="Image" 
                          v-model="dataModal.image"
                          rules="required" 
                        />
                      </div>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-form>
            </validation-observer>
            <template #modal-footer="{close}">
              <div class="text-right">
                <b-button class="btn-rounded" variant="outline-secondary" @click="close">Cancel</b-button>
                <b-button type="button" @click="submitClient" class="btn-rounded ml-2" variant="primary">Save Changes</b-button>
              </div>
            </template>
          </b-modal>
          <b-modal id="modalMachine" size="lg" title="Form Machine" no-close-on-backdrop>
            <validation-observer
              v-slot="{ handleSubmit }" ref="VFormMachine"
            >
              <b-form @submit.prevent="handleSubmit(submitMachine)">
                <b-card-body>
                  <b-row>
                    <b-col md=4>
                      <b-form-group label-for="massTitleId">
                        <label>Title (ID) <span class="text-danger mr5">*</span></label>
                        <b-form-input v-model="dataModal.title_id"></b-form-input>
                        <VValidate name="Title (ID)" v-model="dataModal.title_id" rules="required|min:3|max:160" />
                      </b-form-group>
                    </b-col>
                    <b-col md=4>
                      <b-form-group label-for="massTitleEn">
                        <label>Title (EN) <span class="text-danger mr5">*</span></label>
                        <b-form-input v-model="dataModal.title_en"></b-form-input>
                        <VValidate name="Title (EN)" v-model="dataModal.title_en" rules="required|min:3|max:160" />
                      </b-form-group>
                    </b-col>
                    <b-col md=4>
                      <b-form-group label-for="massTitleEn">
                        <label>Title (JP) <span class="text-danger mr5">*</span></label>
                        <b-form-input v-model="dataModal.title_jp"></b-form-input>
                        <VValidate name="Title (JP)" v-model="dataModal.title_jp" rules="required|min:3|max:160" />
                      </b-form-group>
                    </b-col>
                    <b-col md=4>
                      <b-form-group label-for="unit">
                        <label>Unit <span class="text-danger mr5">*</span></label>
                        <b-form-input type="number" v-model="dataModal.unit"></b-form-input>
                        <VValidate name="Unit" v-model="dataModal.unit" rules="required|min_value:1" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-form>
            </validation-observer>
            <template #modal-footer="{close}">
              <div class="text-right">
                <b-button class="btn-rounded" variant="outline-secondary" @click="close">Cancel</b-button>
                <b-button type="button" @click="submitMachine" class="btn-rounded ml-2" variant="primary">Save Changes</b-button>
              </div>
            </template>
          </b-modal>
          <b-modal id="modalProduct" title="Form Product" no-close-on-backdrop>
            <validation-observer
              v-slot="{ handleSubmit }" ref="VFormProduct"
            >
              <b-form @submit.prevent="handleSubmit(submitProduct)">
                <b-card-body>
                  <b-row>
                    <b-col md=12>
                      <div class="form-group">
                        <label class="control-label">Title ID <span class="text-danger">*</span></label>         
                        <b-form-input v-model="dataModal.title_id" />
                        <VValidate 
                          name="Title ID" 
                          v-model="dataModal.title_id"
                          rules="required|min:3|max:160" 
                        />
                      </div>
                      <div class="form-group">
                        <label class="control-label">Title EN <span class="text-danger">*</span></label>         
                        <b-form-input v-model="dataModal.title_en" />
                        <VValidate 
                          name="Title EN" 
                          v-model="dataModal.title_en"
                          rules="required|min:3|max:160" 
                        />
                      </div>
                      <div class="form-group">
                        <label class="control-label">Title JP <span class="text-danger">*</span></label>         
                        <b-form-input v-model="dataModal.title_jp" />
                        <VValidate 
                          name="Title JP" 
                          v-model="dataModal.title_jp"
                          rules="required|min:3|max:160" 
                        />
                      </div>
                      <div class="form-group">
                        <label class="control-label">Image <span class="text-danger">*</span></label>         
                        <Uploader v-model="dataModal.image" type="product"/>
                        <VValidate 
                          name="Image" 
                          v-model="dataModal.image"
                          rules="required" 
                        />
                      </div>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-form>
            </validation-observer>
            <template #modal-footer="{close}">
              <div class="text-right">
                <b-button class="btn-rounded" variant="outline-secondary" @click="close">Cancel</b-button>
                <b-button type="button" @click="submitProduct" class="btn-rounded ml-2" variant="primary">Save Changes</b-button>
              </div>
            </template>
          </b-modal>

        </b-tab>
        <b-tab title="SEO Settings" v-if="moduleRole('seo')">
          <SEOSection />
        </b-tab>
      </b-tabs>
    </section>
  </b-container>
</template>
<script>
  import GlobalVue from '@/libs/Global.vue'
  import SEOSection from '@/components/SEOSection'
  import Gen from '@/libs/Gen.js'

  export default {
    extends: GlobalVue,
    name: 'BoAnakPerusahaan',
    components: {
      SEOSection
    },
    mounted(){
      this.apiGet()
    },
    data(){
      return {
        row: {
          contentInformation: {},
          contentImage: {},
          contentMachine: {},
          contentClient: {},
          contentProduct: {}
        },
        dataModal: {}
      }
    },
    methods: {
      addMachine(){
        this.dataModal = {}
        this.$bvModal.show('modalMachine')
      },
      editMachine(value){
        this.dataModal = _.clone(value)
        this.$bvModal.show('modalMachine')
      },
      submitMachine(){
        this.$refs.VFormMachine.validate().then(success => {
          if(!success) return
          if(this.dataModal.id){
            // UPDATE
            const index = this.row.contentMachine.lists.findIndex(v => v.id == this.dataModal.id)
            if(index > -1){
              this.$set(this.row.contentMachine.lists, index, this.dataModal)
            }
          }else{
            // ADD
            this.dataModal.id = Math.floor(Math.random() * 999)
            this.row.contentMachine.lists.push(this.dataModal)
          }
          this.$bvModal.hide('modalMachine')
        })
      },
      deleteMachine(index){
        this.row.contentMachine.lists.splice(index, 1)
      },
      addImage(){
        this.dataModal = {}
        this.$bvModal.show('modalImage')
      },
      editImage(value){
        this.dataModal = _.clone(value)
        this.$bvModal.show('modalImage')
      },
      deleteImage(index){
        this.row.contentImage.splice(index, 1)
      },
      submitImage(){
        this.$refs.VFormImage.validate().then(success => {
          if(!success) return

          if(!this.row.contentImage) this.row.contentImage = []
          if(this.dataModal.id){
            // UPDATE
            const index = this.row.contentImage.findIndex(v => v.id == this.dataModal.id)
            if(index > -1){
              this.$set(this.row.contentImage, index, this.dataModal)
            }
          }else{
            // ADD
            this.dataModal.id = Math.floor(Math.random() * 999)
            this.row.contentImage.push(this.dataModal)
          }
          this.$bvModal.hide('modalImage')
        })
      },
      addClient(){
        this.dataModal = {}
        this.$bvModal.show('modalClient')
      },
      editClient(value){
        this.dataModal = _.clone(value)
        this.$bvModal.show('modalClient')
      },
      deleteClient(index){
        this.row.contentClient.lists.splice(index, 1)
      },
      submitClient(){
        this.$refs.VFormClient.validate().then(success => {
          if(!success) return
          if(this.dataModal.id){
            // UPDATE
            const index = this.row.contentClient.lists.findIndex(v => v.id == this.dataModal.id)
            if(index > -1){
              this.$set(this.row.contentClient.lists, index, this.dataModal)
            }
          }else{
            // ADD
            this.dataModal.id = Math.floor(Math.random() * 999)
            this.row.contentClient.lists.push(this.dataModal)
          }
          this.$bvModal.hide('modalClient')
        })
      },
      addProduct(){
        this.dataModal = {}
        this.$bvModal.show('modalProduct')
      },
      editProduct(value){
        this.dataModal = _.clone(value)
        this.$bvModal.show('modalProduct')
      },
      deleteProduct(index){
        this.row.contentProduct.lists.splice(index, 1)
      },
      submitProduct(){
        this.$refs.VFormProduct.validate().then(success => {
          if(!success) return
          if(this.dataModal.id){
            // UPDATE
            const index = this.row.contentProduct.lists.findIndex(v => v.id == this.dataModal.id)
            if(index > -1){
              this.$set(this.row.contentProduct.lists, index, this.dataModal)
            }
          }else{
            // ADD
            this.dataModal.id = Math.floor(Math.random() * 999)
            this.row.contentProduct.lists.push(this.dataModal)
          }
          this.$bvModal.hide('modalProduct')
        })
      },
      doSubmit(){
        this.$refs.VForm.validate().then(success => {
          if(!success) return

          this.swalLoading()

          Gen.apiRest('/do/' + this.modulePage, 
              { data: { type: 'update', ...this.row } }
            ).then(res => {
              if(res){
                this.$swal({
                  title: res.data.message,
                  icon: 'success',
                }).then(() => {
                  this.apiGet()
                })
              }
            }).catch(err => {
              this.$swal({title: err.response.data.message, icon:'error'})
            })
        })
      }
    }
  }
</script>
